import { AppInfoModel, RouteDictionary, UserModel } from '@models/shared/shared-models';
import { apiUrlStore } from './api-url-store';

export const environment = {
    name: 'dev',
    hmr: false,
    serviceLocatorUrl: 'https://dev-svc-locator.euclidrcm.com',
    azureAd: {
        clientId: '93e0fc42-b8c6-4b44-ac7d-d6a49610999a'
    },
    urlStore: apiUrlStore,
    appInfo: {} as AppInfoModel,
    userInfo: {} as UserModel,
    routeDictionary: {} as RouteDictionary,
    activeTenants: [],
    isProd() {
        return this.name === 'prd';
    },
    recaptcha: {
        siteKey: '6LfDffcpAAAAADa_zTBtiZyy9Oq3LP7tsoc_mhCx',
    },
};